import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';

import * as skrollr from 'skrollr';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $fancybox = $('a.fancybox'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		$contactPersonMenu = $('.contact-person-menu');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($map) {
		googleMap.init();
	}

	$('.menu-nav, .overlay-menu').on('click', 'a', function(){
		if($(this).parent().hasClass('init-car-nav')) {
			$('#category-filter li:first-child a').trigger('click');

			$('body').toggleClass('active-nav');

			$('.overlay').removeClass('open');

			return false;
		}
	});

	$('.close-filter').on('click', function(){
		$('body').removeClass('active-nav');
	});

	$('.car-offer-filter').on('click', 'a', function(){
		var dataOffer = $(this).attr('data-offer');

		$('.dots-menu').removeClass('active');

		$('.car-offer-filter a').removeClass('active');
		$(this).addClass('active');

		$('.car-offer').removeClass('active');
		$('.car-offer').eq(dataOffer).addClass('active');

		$('.dots-menu').eq(dataOffer).addClass('active');

		return false;
	});

	$('.car-offer-filter li:first-child a').addClass('active');
	$('.car-offer-new').addClass('active');
	$('.dots-hld').addClass('active');

	$('.filters').on("click", "li a", function() { 
		var thisItem = $(this),
			dataCategory = thisItem.attr('data-filter');
		
		$('#category-grid').addClass('filtering');

		thisItem.parents('ul.filters').find('li').removeClass('active');
		thisItem.parent('li').addClass('active');

		if(dataCategory !== 'all') {

			$('.grid-item').fadeOut();

			$('.grid-item').each(function(){
				var $self = $(this),
					itemCategory = $(this).attr('data-category');

				if(dataCategory === itemCategory) {
					setTimeout(function(){
						$self.fadeIn(function(){
							$('#category-grid').removeClass('filtering');
						});
					}, 500)
				}
			});

		} else {
			setTimeout(function(){
				$('.grid-item').fadeIn(function(){
					$('#category-grid').removeClass('filtering');
				});
			}, 500)
		}
		
		return false;
	});

	$('.check-all input').on('click', function() {
		$('.agreement--sec').find('input').not(this).prop('checked', this.checked);
	});
	$('.agreement--sec input').on('click', function() {
		$('.check-all').find('input').not(this).prop('checked', this.checked);
	});

	if($contactPersonMenu.length) {
		var $contactPersonTitle = $contactPersonMenu.find('.title'),
			firstDataCat = $contactPersonMenu.find('li:first-child a').attr('data-cat'),
			contactPersonH3 = $('.contact-person-container h3').find('span'),
			contactPersonTitleVal = $contactPersonMenu.find('li:first-child a').html();

		// $contactPersonTitle.html(contactPersonTitleVal);

		$('[data-related-cat="'+firstDataCat+'"]').fadeIn();

		$contactPersonMenu.find('a').on('click', function(){
			var _self = $(this),
				thisVal = _self.html(),
				thisData = _self.attr('data-cat');

			$contactPersonTitle.html(thisVal);
			contactPersonH3.html(thisVal);

			$('[data-related-cat]').fadeOut(function(){
				setTimeout(function(){
					$('[data-related-cat="'+thisData+'"]').fadeIn();
				}, 300);
			});

			return false;
		});
	}

});

$(window).on('load', function(){
	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			easing: 'cubic',
			smoothScrolling: false,
			forceHeight: false
		});
	}
});