export function init() {

	var $mainSlider = document.querySelector('.main-slider'),
		$carCarousel = document.querySelectorAll('.car-carousel'),
		$carCarouselSec = document.querySelectorAll('.car-carousel-sec'),
		$sliderGalleryHld = document.querySelector('.slider-gallery-hld'),
		$sliderNumbers = document.querySelector('.slider-numbers');

	if($mainSlider) {
		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			asNavFor: '.slider-numbers',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderNumbers) {
		$('.slider-numbers').slick({
			autoplay: false,
		    arrows: false,
		    dots: false,
		    slidesToShow: 4,
		    centerPadding: "20px",
		    draggable: false,
		    infinite: true,
		    pauseOnHover: false,
		    swipe: false,
		    touchMove: false,
		    vertical: true,
		    asNavFor: '.main-slider',
		    speed: 1000,
		    autoplaySpeed: 2000,
		    focusOnSelect: true,
		    useTransform: true,
		    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
		    adaptiveHeight: true,
		});
	}

	if($carCarousel) {
		$('.car-carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 7,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			appendArrows: document.querySelector('.dots-hld'),
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1400,
		      	settings: {
			        slidesToShow: 5,
			        slidesToScroll: 3
		      	}
		   	},
		   	{
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 2
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($carCarouselSec) {
		$('.car-carousel-sec').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 7,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			appendArrows: document.querySelector('.dots-hld-sec'),
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1400,
		      	settings: {
			        slidesToShow: 5,
			        slidesToScroll: 3
		      	}
		   	},
		   	{
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 2
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($sliderGalleryHld) {
		$('.slider-box-big').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			asNavFor: '.slider-box-small',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});

		$('.slider-box-small').slick({
			autoplay: false,
		    arrows: true,
		    dots: false,
		    slidesToShow: 4,
		    centerPadding: "20px",
		    draggable: false,
		    infinite: true,
		    pauseOnHover: false,
		    swipe: false,
		    touchMove: false,
		    vertical: true,
		    asNavFor: '.slider-box-big',
		    speed: 1000,
		    focusOnSelect: true,
		    useTransform: true,
		    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
		    prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
		    adaptiveHeight: true,
		    responsive: [
		   	{
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2
		      	}
		   	}]
		});
	}

}